import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://cecabf1211dfa54a6c4c7aa7835c9fc4@o4506533474664448.ingest.sentry.io/4506533478662144",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,

  // Optional: Initialize Session Replay:
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});